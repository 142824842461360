import React from 'react';

import { Meta } from '../components/Meta';
import { MainLayout } from '../components/MainLayout';
import { HeroSection } from '../components/HeroSection';
import { ServiceDetailsSection } from '../components/ServiceDetailsSection';

import HeroImage from '../images/stonework.webp';
import Driveways from '../images/driveways.webp';
import Walkways from '../images/walkways.webp';
import Pools from '../images/pools.webp';
import Aprons from '../images/aprons.webp';
import Steps from '../images/steps.webp';
import Fireplaces from '../images/fireplaces.webp';
import DrivewayClearing from '../images/driveway-clearing.webp';
import DrivewayGrating from '../images/driveway-grating.webp';
import Grills from '../images/grills.webp';

const heroTitle = "Nantucket's Stonework Experts";
const heroSubtitle = 'Servicing Nantucket for over 20  years.';

const serviceTitle = 'Merlos Stone Masonry';
const serviceSubtitle =
  'Make your property standout with our amazing stonework. Outdoor fireplaces, patios, pool patios, walkways, driveways, driveway aprons, steps, plus anything you might want for your outdoor pleasure. Our craftsmen will help you design and choose what best suits your needs.';
const services = [
  {
    title: 'Driveways',
    description:
      'The Merlos team can help you design the perfect driveway for your home. Gravel, shell, stone, we have experience with all types of driveways.',
    image: Driveways,
  },
  {
    title: 'Walkways',
    description:
      'Make a grand entrance to your home with belgian block, marble, blue stone, or any other stone you like. Our team can design and install the perfect statement.',
    image: Walkways,
  },
  {
    title: 'Pools',
    description:
      'Our stone experts can transform your pool area into a place of pleasure with our amazing and unique stone work that we can tailor to your taste.',
    image: Pools,
  },
  {
    title: 'Driveway Aprons',
    description:
      'Merlos can repair, replace or install a new apron to your driveway. Our team will design and develop an apron that will last for decades.',
    image: Aprons,
  },
  {
    title: 'Steps',
    description:
      'Our experts can design and build stone steps anywhere on your property that will make a lasting impression.',
    image: Steps,
  },
  {
    title: 'Fireplaces',
    description:
      'Get full use of your outdoor space with stone benches and a stone fireplace — a great way to add value to your home and enjoy the summer nights.',
    image: Fireplaces,
  },
  {
    title: 'Driveway Clearing',
    description:
      'Whether a new build or large renovation, we can provide land and driveway clearing to help build an amazing entrance to your home.',
    image: DrivewayClearing,
  },
  {
    title: 'Driveway Grating',
    description: 'Our team can provide driveway resurfacing and grating depending on the type of drivieway.',
    image: DrivewayGrating,
  },
  {
    title: 'Outdoor Grills',
    description: 'Our stone experts can help you design the perfect outdoor grill setup for your patio and home.',
    image: Grills,
  },
];

export default function LawnCarePage() {
  return (
    <MainLayout>
      <Meta route="stonework" />
      <HeroSection title={heroTitle} subtitle={heroSubtitle} image={HeroImage} />
      <ServiceDetailsSection title={serviceTitle} subtitle={serviceSubtitle} services={services} />
    </MainLayout>
  );
}
